import "./airesponse.css";
import React, { useState, useEffect } from "react";
import {
  validateAccessToken,
  fetchUserInfo,
  handleUserInfo,
  handleLogout,
  handleLogin,
  fetch_Api,
  delay,
} from "../../Utils/auth";
import { useGoogleLogin } from "@react-oauth/google";
import { useLoginContext } from "../../Context/login-context";
import {
  Container,
  Box,
  Grid,
  Typography,
  CircularProgress,
  Button,
  TextareaAutosize,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Logo from '../../images/airesponse/Ailogo.png';
import SuccessStory from "../../Components/SuccessStory/successstory";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Typewriter from "../../Components/Typing/typing";
import { fetchAiResponse, incrementLogin } from '../../APIs/apifunctions';



function Airesponse() {
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [InputText, setInputText] = useState("");
  const [AiResponse, setAiResponse] = useState("");
  const [showResponse, setShowResponse] = useState(false);
  const [IsLoading, setIsLoading] = useState(false);
  const [copied, setCopied] = useState(false);
  const [reset, setReset] = useState(false);
  const { isLoggedIn, setIsLoggedIn, accessToken, setAccessToken } =
    useLoginContext();

  const handleInputChange = (Event) => {
    setInputText(Event.target.value);
  };


  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "AI Response | Restaverse";
    handleUserInfo(setAccessToken, setIsLoggedIn, validateAccessToken);
  }, []);

  const handleGetResponse = async () => {
    setIsLoading(true);
    setReset(true);
    try {
      const response = await fetchAiResponse(InputText);
      setAiResponse(response);
      setShowResponse(true);
      setReset(false);

      const userInfo = JSON.parse(localStorage.getItem("userInfo"));
      const userEmail = userInfo.email;
      await incrementLogin(userEmail, "responsecount");
    } catch (error) {
      console.error("Error fetching AI response", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCopyResponse = () => {
    navigator.clipboard.writeText(AiResponse);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1500);
  };



  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      console.log("Google login successful", tokenResponse);
      await handleLogin(tokenResponse, setIsLoggedIn, setAccessToken, fetchUserInfo, validateAccessToken, fetch_Api);

      const userInfo = localStorage.getItem("userInfo");
      if (userInfo) {
        const parsedUserInfo = JSON.parse(userInfo);
        const email = parsedUserInfo.email;

        try {
          await incrementLogin(email, "airesponse");
        } catch (error) {
          console.error("Error incrementing login count:", error);
        }
      } else {
        console.error("User info not found in localStorage");
      }

      if (InputText) {
        handleGetResponse();
      }
    },
    onError: () => {
      console.log("Login Failed");
    },
    redirect_uri: "postmessage",
  });


  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 600); // Adjust the breakpoint as needed
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <Box sx={{ width: "100%", padding: 0 }}>
        <Box
          className="main"
          sx={{ flexGrow: 1, padding: { xs: "2rem", sm: "4rem", md: "6rem" } }}
        >
          <Grid container spacing={2}>
            {isSmallScreen && (
              <Grid item xs={12}>
                <Box
                  className="response-generator"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginBottom: 2,
                  }}
                >
                  <Typography
                    style={{
                      fontSize: "9vw",
                      color: "white",
                      textAlign: "left",
                    }}
                    sx={{ marginLeft: "12%" }}
                    variant="h5"
                  >
                    See how our AI tool will reply to your restaurant review!
                    <img
                      src={Logo}
                      alt="Logo"
                      className=""
                      style={{ width: "10%", height: "auto", marginLeft:"7px" }}
                    />
                  </Typography>
                </Box>
              </Grid>
            )}

            <Grid
              item
              xs={12}
              md={6}
              className={isSmallScreen ? "main-sec-two" : "main-sec-one"}
              sx={{ order: isSmallScreen ? 2 : 1 }}
            >
              <Box sx={{ padding: 2, borderRadius: 2 }}>
                {!isSmallScreen && (
                  <Box
                    className="response-generator"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: 2,
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: "3vw",
                        color: "white",
                        textAlign: "left",
                        paddingBottom: "1rem",
                      }}
                      sx={{ marginLeft: { xl: "12%" } }}
                      variant="h5"
                    >
                      See how our AI tool will reply to your restaurant review!
                      <img
                        src={Logo}
                        alt="Logo"
                        style={{ width: "10%", height: "auto", marginLeft:"7px" }}
                      />
                    </Typography>
                  </Box>
                )}

                <Box
                  sx={{
                    backgroundColor: "white",
                    borderRadius: 2,
                    boxShadow: 1,
                    padding: 2,
                    marginTop: "2%",
                    marginLeft: { xl: "10%" },
                  }}
                >
                  <TextareaAutosize
                    minRows={4}
                    maxRows={4}
                    className="textarea"
                    name="text"
                    placeholder="Add your Text.."
                    value={InputText}
                    onChange={handleInputChange}
                    style={{
                      width: "100%",
                      marginTop: "10px",
                      padding: "8px",
                      borderRadius: "8px",
                      border: "1px solid white",
                      resize: "none",
                      outline: "none",
                      boxSizing: "border-box",
                      overflowY: "auto",
                    }}
                  ></TextareaAutosize>
                  <Box
                    sx={{
                      backgroundColor: "white",
                    }}
                  >
                    {showResponse && (
                      <>
                        <Box
                          className="divider"
                          sx={{
                            height: "1px",
                            width: "100%",
                            backgroundColor: "grey",
                            marginTop: "5px",
                          }}
                        ></Box>
                        <Box
                          className="response-container"
                          sx={{
                            padding: 2,
                            backgroundColor: "white",
                            borderRadius: 2,
                            overflowY: "auto",
                          }}
                        >
                          <Typography
                            variant="body1"
                            sx={{
                              fontStyle: "italic",
                              fontWeight: 500,
                              color: "black",
                            }}
                          >
                            AI Response
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{
                              color: "black",
                              overflowY: "auto",
                              maxHeight: "100px", 
                              width: "100%",

                            }}
                          >
                            <Typewriter
                              text={AiResponse}
                              delay={80}
                              reset={reset}
                              

                            />
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "flex-end",
                              marginTop: "5px",
                            }}
                          >
                            <Tooltip
                              title={copied ? "Copied" : "Copy"}
                              slotProps={{
                                popper: {
                                  sx: {
                                    [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                                    {
                                      marginTop: '1px',
                                    }
                                  },
                                },
                              }}
                            >
                              <ContentCopyIcon
                                className="copy-button"
                                onClick={handleCopyResponse}
                                fontSize="small"
                                sx={{ cursor: "pointer", marginBottom: "-2px" }}
                              />
                            </Tooltip>

                          </Box>
                        </Box>
                      </>
                    )}
                  </Box>
                </Box>
              </Box>
            </Grid>



            <Grid
              item
              xs={12}
              md={6}
              className={
                isSmallScreen
                  ? "main-sec-one pt-3 pl-3"
                  : "main-sec-two pt-3 pl-3 mb-3"
              }
              sx={{ order: isSmallScreen ? 1 : 2 }}
            >
              <div
                style={{ borderRadius: "1rem" }}
                className={"d-flex flex-column bg-white h-100 p-4 m-3"}
              >
                <div className="">
                  <Typography
                    variant="h5"
                    sx={{
                      fontWeight: "800",
                      fontSize: { md: "2rem", xs: "1.25rem" },
                    }}
                  >
                    Let’s get started
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      marginTop: "1rem",
                      fontSize: { md: "1.5rem", xs: "0.75rem" },
                    }}
                  >
                    <b>Step 1:</b> Add your text.
                    <br />
                    <b>Step 2:</b> Click on the Get Response button below to
                    generate a response.
                    <br />
                    <b>Step 3:</b> Click on the copy button to copy the
                    response.
                  </Typography>
                </div>
                <div className="d-flex flex-1 h-100 justify-content-center align-items-center">
                  <div className="mt-4">
                    {isLoggedIn ? (
                      <div>
                        <Button
                          onClick={handleGetResponse}
                          disabled={IsLoading}
                          variant="contained"
                          sx={{
                            backgroundColor: "#6c41ff",
                            color: "white",
                            padding: { xl: "10px 20px", xs: "5px 10px" },
                            fontSize: { xl: "1rem", xs: "0.75rem" },
                            width: { xl: "250px", xs: "200px" },
                            textTransform: "none", // Add this line to override text transformation
                            "&:hover": {
                              backgroundColor: "#6c41ff",
                            },
                            "&.Mui-disabled": {
                              backgroundColor: "#6c41ff",
                              color: "white",
                            },
                          }}
                        >
                          {IsLoading ? (
                            <CircularProgress size={24} color="inherit" />
                          ) : (
                            "Get Response."
                          )}
                        </Button>
                        <Typography
                          sx={{
                            textAlign: "center",
                            fontSize: {
                              xl: "14px",
                              xs: " 10px",
                            },
                            color: "grey",
                            fontWeight: "bold",
                            marginTop: "10px",
                          }}
                        >
                          * No Credit Card required
                        </Typography>
                      </div>
                    ) : (
                      <div>
                        <Button
                          onClick={login}
                          variant="contained"
                          sx={{
                            backgroundColor: "#6c41ff",
                            color: "white",
                            padding: { xl: "10px 20px", xs: "5px 10px" },
                            fontSize: { xl: "1.25rem", xs: "0.75rem" },
                            width: { xl: "250px", xs: "200px" },
                            textTransform: "none", // Add this line to override text transformation
                            "&:hover": {
                              backgroundColor: "#6c41ff",
                            },
                          }}
                        >
                          Get Response.
                        </Button>
                        <Typography
                          sx={{
                            textAlign: "center",
                            fontSize: {
                              xl: "14px",
                              xs: " 10px",
                            },
                            color: "grey",
                            marginTop: "10px",
                            fontWeight: "bold",
                          }}
                        >
                          * No Credit Card required
                        </Typography>
                      </div>
                    )}
                  </div>
                </div>

                {/* <Typography
                  sx={{
                    textAlign: "center",
                    fontSize: {
                      xl: "14px",
                      xs: " 10px",
                    },
                    color: "grey",
                    marginTop: "-6px",
                    fontWeight: "bold",
                  }}
                >
                  * No Credit Card required
                </Typography> */}
              </div>
            </Grid>
          </Grid>
        </Box>

        <Box
          className="second-section"
          sx={{
            backgroundColor: "rgb(247, 245, 255)",
            width: "100%",
            padding: 2,
          }}
        >
          <Grid container justifyContent="center" sx={{ padding: 2 }}>
            <Grid item xs={12} md={8}>
              <Typography
                variant="h4"
                sx={{
                  textAlign: "center",
                  fontSize: { md: "3rem", xs: "2rem" },
                  marginBottom: 2,
                  paddingY: "2rem",
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: 900,
                }}
              >
                What is Restaverse AI?
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  textAlign: "left",
                  marginBottom: 4,
                  fontSize: { md: "1rem", xs: "1rem" },
                  marginLeft: "auto",
                  marginRight: "auto",
                  maxWidth: "100%",
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: 400,
                }}
              >
                Restaverse AI helps the restaurant industry focus on key
                productive things in the fastest turnaround time, here are some
                of the things you can leverage:
              </Typography>
              <Grid
                container
                rowSpacing={2}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                {[
                  "Advanced customer review management across Google, Zomato, Swiggy, Tripadvisor, Meta, etc in real-time",
                  "AI driven budget allocation across online food delivery platforms in minutes",
                  "Operational problem identification across your stores in seconds",
                  "ML driven customer feedback analysis and segmenting",
                ].map((text, index) => (
                  <Grid item xs={12} sm={6} key={index} className="pb-4">
                    <Box sx={{ display: "flex" }}>
                      <CheckCircleIcon
                        fontSize="large"
                        sx={{ color: "rgb(50,205,50)" }}
                      />
                      <Typography
                        variant="body1"
                        sx={{
                          marginLeft: 1,
                          fontSize: { md: "1rem", xs: "1rem" },
                          fontFamily: "Poppins, sans-serif",
                          fontWeight: 400,
                        }}
                      >
                        {text}
                      </Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <div className="container-lg">
          <SuccessStory
            titletext="Explore our success stories"
            desctext="Dive into the narratives of innovation and triumph with Restaverse's detailed case studies."
            buttontext="Discover More"
            redirect="/case-studies/Greenr-cafe/"
          />
        </div>
      </Box>
    </>
  );
}

export default Airesponse;
