import { useState } from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './cscarousel.css';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import persiandarbarlogo from "../../images/CaseStudy/casestudylogos/persiandarbar.webp";
import ufclogo from "../../images/CaseStudy/casestudylogos/ufc.webp";
import persiadarbarlogo from "../../images/CaseStudy/casestudylogos/persiadarbar.webp";
import silverbeach from "../../images/CaseStudy/casestudylogos/sbfc.webp";
import nomnom from "../../images/CaseStudy/casestudylogos/nomnom.webp";
import tbse from "../../images/CaseStudy/casestudylogos/tbse.webp";
import greenr from "../../images/CaseStudy/greenr/greenrlogo.webp";
import goma from "../../images/CaseStudy/goma/gomalogo.webp";
import shm from "../../images/CaseStudy/shm/shmlogo.webp";
import meetha from "../../images/CaseStudy/meetha/meethalogo.webp";

// Custom Next Button
const NextArrow = ({ onClick }) => {
    return (
        <div className="arrow prev" onClick={onClick}>
            <FaChevronRight />
        </div>
    );
};

// Custom Previous Button
const PrevArrow = ({ onClick }) => {
    return (
        <div className="arrow next" onClick={onClick}>
            <FaChevronLeft />
        </div>
    );
};

const CasestudyCardCarousel = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        rows: 2,
        autoplay: true,
        swipeToSlide: true,
        autoplaySpeed: 2000,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [

            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    rows: 2
                }
            },

            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            }

        ]

    };


    return (
        <div className="slider-container">
            <Slider {...settings}>

                <div className="cases">
                    <a href={"/case-studies/United-farmers-creamery/"} style={{ textDecoration: "none", fontFamily: "'Poppins', sans-serif", }}>
                        <div className="casestudycard">
                            <div
                                className="logocasestudycard"
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                {/* {casestudycardtitle} */}
                                <img
                                    src={ufclogo}
                                    alt={"UFC"}
                                    style={{ maxWidth: "40%" }}
                                />
                            </div>
                            <div className="content" style={{ color: "black" }}>
                                {"Revenue growth via better search engine optimization"}
                            </div>

                            <div className="learnmore">
                                Learn more <FontAwesomeIcon icon={faArrowRight} />
                            </div>
                        </div>
                    </a>
                </div>
{/* 
                <div className="cases">
                    <a href={"/case-studies/nom-nom/"} style={{ textDecoration: "none", fontFamily: "'Poppins', sans-serif", }}>
                        <div className="casestudycard">
                            <div
                                className="logocasestudycard"
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <img
                                    src={nomnom}
                                    alt={"Nom Nom"}
                                    style={{ maxWidth: "40%" }}
                                />
                            </div>
                            <div className="content" style={{ color: "black" }}>
                                {"Revenue growth via better menu optimisation"}
                            </div>

                            <div className="learnmore">
                                Learn more <FontAwesomeIcon icon={faArrowRight} />
                            </div>
                        </div>
                    </a>
                </div> */}
                <div className="cases">
                    <a href={"/case-studies/Silver-beach-cafe/"} style={{ textDecoration: "none", fontFamily: "'Poppins', sans-serif", }}>
                        <div className="casestudycard">
                            <div
                                className="logocasestudycard"
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                {/* {casestudycardtitle} */}
                                <img
                                    src={silverbeach}
                                    alt={"Silver Beach"}
                                    style={{ maxWidth: "40%" }}
                                />
                            </div>
                            <div className="content" style={{ color: "black" }}>
                                {"Revenue growth via revamping brand positioning"}
                            </div>

                            <div className="learnmore">
                                Learn more <FontAwesomeIcon icon={faArrowRight} />
                            </div>
                        </div>
                    </a>
                </div>

                <div className="cases">
                    <a href={"/case-studies/Greenr-cafe/"} style={{ textDecoration: "none", fontFamily: "'Poppins', sans-serif", }}>
                        <div className="casestudycard">
                            <div
                                className="logocasestudycard"
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                {/* {casestudycardtitle} */}
                                <img
                                    src={greenr}
                                    alt={"Greenr Cafe"}
                                    style={{ maxWidth: "40%" }}
                                />
                            </div>
                            <div className="content" style={{ color: "black" }}>
                                {"Customer Engagement Enhancement through Strategic Menu Optimization"}
                            </div>

                            <div className="learnmore">
                                Learn more <FontAwesomeIcon icon={faArrowRight} />
                            </div>
                        </div>
                    </a>
                </div>
                <div className="cases">
                    <a href={"/case-studies/Goma/"} style={{ textDecoration: "none", fontFamily: "'Poppins', sans-serif", }}>
                        <div className="casestudycard">
                            <div
                                className="logocasestudycard"
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                {/* {casestudycardtitle} */}
                                <img
                                    src={goma}
                                    alt={"UFC"}
                                    style={{ maxWidth: "40%" }}
                                />
                            </div>
                            <div className="content" style={{ color: "black" }}>
                                {"Revenue growth via introducing online delivery"}
                            </div>

                            <div className="learnmore">
                                Learn more <FontAwesomeIcon icon={faArrowRight} />
                            </div>
                        </div>
                    </a>
                </div>
                <div className="cases">
                    <a href={"/case-studies/Meetha/"} style={{ textDecoration: "none", fontFamily: "'Poppins', sans-serif", }}>
                        <div className="casestudycard">
                            <div
                                className="logocasestudycard"
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                {/* {casestudycardtitle} */}
                                <img
                                    src={meetha}
                                    alt={"meetha"}
                                    style={{ maxWidth: "40%" }}
                                />
                            </div>
                            <div className="content" style={{ color: "black" }}>
                                {"Revenue growth via through effective SEO strategies"}
                            </div>

                            <div className="learnmore">
                                Learn more <FontAwesomeIcon icon={faArrowRight} />
                            </div>
                        </div>
                    </a>
                </div>
                <div className="cases">
                    <a href={"/case-studies/SHM/"} style={{ textDecoration: "none", fontFamily: "'Poppins', sans-serif", }}>
                        <div className="casestudycard">
                            <div
                                className="logocasestudycard"
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                {/* {casestudycardtitle} */}
                                <img
                                    src={shm}
                                    alt={"shm"}
                                    style={{ maxWidth: "40%" }}
                                />
                            </div>
                            <div className="content" style={{ color: "black" }}>
                                {"Revenue growth via menu enhancement strategies"}
                            </div>

                            <div className="learnmore">
                                Learn more <FontAwesomeIcon icon={faArrowRight} />
                            </div>
                        </div>
                    </a>
                </div>
            </Slider>
        </div>
    );
};

export default CasestudyCardCarousel;
